<template>
  <div class="">

    <b-tabs
      content-class="mt-2"
      justified
      align="center"
      class="classTab"
    >
      <b-tab
        title="Formulario"
        active
        class="pb-2"
      >
        <newFormulario :id-formulario="idFormulario" />

      </b-tab>
      <b-tab
        :title="`Respuestas (${cantRespuestas})`"
      >
        <RespuestasFormularioIndex :id-formulario="idFormulario" />
      </b-tab>
      <b-tab title="Configuración">
        <b-card-text>
          Por trabajar
        </b-card-text>

      </b-tab>

    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs, BTab, BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import RespuestasFormularioIndex from '@/views/administrador/gestion-formulario/nuevo-formulario/respuestas/RespuestasFormularioIndex.vue'
import newFormulario from '@/views/administrador/gestion-formulario/nuevo-formulario/edit-formulario/newFormulario.vue'

export default {
  components: {

    BTabs,
    BTab,
    BCardText,

    // extern
    newFormulario,
    RespuestasFormularioIndex,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      cantRespuestas: 0,

      idFormulario: parseInt(this.$route.params.idFormulario, 10), // id del fromulario para mostrar sus respuestas
    }
  },

  async mounted() {
    await store.dispatch('newFormularioModule/getDataFormulario', this.$route.params.idFormulario)
      .then(res => {
        this.cantRespuestas = res.data.n_respuestas
      })
  },

}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
